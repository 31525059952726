export enum SupportedLanguages {
  English = "en",
  Spanish = "es",
}

export enum SupportedCountries {
  UnitedStates = "US",
  Canada = "CA",
  Mexico = "MX",
  Argentina = "AR",
  Colombia = "CO",
}
