import { SupportedCountries } from "@/enums/internationalization";
import { parsePhoneNumber } from "awesome-phonenumber";

const PhoneExtensionRegex = /(,|;ext=|;)/g;
const PhoneExtensionPrefix = ";ext=";

export const PhoneExtensionUiPrefix = "x";

const GetPhoneNumberAndExtension = (
  phone: string
): { phone: string; extension: string } => {
  const phoneParts = phone.split(PhoneExtensionRegex);

  if (phoneParts.length < 3) {
    return {
      phone: phoneParts[0],
      extension: "",
    };
  }

  return {
    phone: phoneParts[0],
    extension: phoneParts[2] ?? "",
  };
};

export const GetPhoneNumberForDb = (
  phoneNumber: string,
  country: SupportedCountries
): string | null => {
  const { phone, extension } = GetPhoneNumberAndExtension(phoneNumber.trim());
  const parsedNumber = parsePhoneNumber(phone.trim(), { regionCode: country });
  let phoneParsed = parsedNumber?.valid ? parsedNumber.number.e164 : null;

  if (!extension || !phoneParsed) {
    return phoneParsed;
  }

  return phoneParsed + PhoneExtensionPrefix + extension;
};

export const GetPhoneNumberForUI = (
  phoneNumber: string,
  country: SupportedCountries,
  separateExtension?: string
): string => {
  const phoneToUse = `${phoneNumber}${
    separateExtension ? `${PhoneExtensionPrefix}${separateExtension}` : ""
  }`;

  const { phone, extension } = GetPhoneNumberAndExtension(phoneToUse.trim());
  const parsedNumber = parsePhoneNumber(phone, { regionCode: country });

  const NationalOrInternational =
    parsedNumber.regionCode === country
      ? parsedNumber?.number?.national
      : parsedNumber?.number?.international;

  let phoneParsed = NationalOrInternational
    ? NationalOrInternational
    : phone.trim();

  if (!extension) {
    return phoneParsed;
  }

  return `${phoneParsed} ${PhoneExtensionUiPrefix}${extension}`;
};
