export const UppercaseFirstLetter = (str: string) => {
  return (str || "").charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const SplitAndCapitalize = (str: string) => {
  return (str || "")
    .split("_")
    .map((word) => UppercaseFirstLetter(word))
    .join(" ");
};

export const SplitPascal = (str: string) => {
  return (str || "")
    .split(/(?=[A-Z])/)
    .map((word) => UppercaseFirstLetter(word))
    .join(" ");
};

export const URL_REG_EXP =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
